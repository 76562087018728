import React from 'react';
import BorderBuddyApp from './HomePage';

function App() {
  return (
    <div className="App">
      <BorderBuddyApp />
    </div>
  );
}

export default App;