import React, { useState } from 'react';
import { Youtube, Linkedin } from 'lucide-react';
import borderBuddyLogo from './icon2.png';
import hackTrinityLogo from './hacktrin.png';

export default function BorderBuddyApp() {
  const [activeTab, setActiveTab] = useState('YouTube video');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const linkedInProfiles = [
    { name: "Agnetha Kamath", url: "https://www.linkedin.com/in/agnethakamath/" },
    { name: "Aniij Sharma", url: "https://www.linkedin.com/in/aniij-s-7690581b2/" },
    { name: "Sam Forster", url: "https://www.linkedin.com/in/sampforster/" },
    { name: "Stephen Haskins", url: "https://www.linkedin.com/in/stephen-haskins-6240a024b/" },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when the form is submitted
    try {
      const response = await fetch('/api/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      if (response.ok) {
        setSubmitted(true);
      } else {
        console.error('Failed to submit email');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false); // Set loading to false after the request completes
    }
  };

  return (
    <div className="container mx-auto p-4 flex flex-col min-h-screen">
      <header className="flex justify-between items-center mb-8">
        <div className="flex items-center">
          <img src={borderBuddyLogo} alt="BorderBuddy AI Logo" className="h-10 mr-2" />
          <h1 className="text-2xl font-bold" style={{ color: '#007dff' }}>BorderBuddy AI</h1>
        </div>
        <img src={hackTrinityLogo} alt="hackTrinity Logo" className="h-10" />
      </header>

      <main className="flex-grow">
        <section className="mb-8 border rounded-lg p-6 shadow-sm">
          <h2 className="text-2xl font-bold mb-4" style={{ color: '#007dff' }}>What is BorderBuddy AI?</h2>
          <p className="mb-4">BorderBuddy AI is the legal assistant for travel. Provide your travel details, and BorderBuddy will provide you with up-to-date legal information and visa requirements for your destination.</p>
          <div className="flex flex-col md:flex-row gap-8 mb-4">
            <div className="flex-1">
              <h3 className="font-bold mb-2">Features:</h3>
              <ul className="list-disc list-inside">
                <li>Quick text-based queries</li>
                <li>File upload for detailed itineraries</li>
                <li>Accurate visa requirement information</li>
                <li>Duration validation and additional notes for your stay</li>
              </ul>
            </div>
            <div className="flex-1">
              <h3 className="font-bold mb-2">Simply Enter:</h3>
              <ul className="list-disc list-inside">
                <li>Your Nationality</li>
                <li>At Least One Destination</li>
                <li>The Length of Your Stay in Each Country</li>
                <li>Any Other Relevant Information</li>
              </ul>
            </div>
          </div>
          <p className="mb-4">
            BorderBuddy AI was developed during{' '}
            <a href="https://hacktrinity.com/" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
              HackTrinity
            </a>, 
            the Trinity College Dublin Hackathon. Under the event's theme of Generative AI and Law, we created BorderBuddy to tackle the difficulty of understanding visa and immigration law. In less than 10 hours of development time, we created our fully functional application to demonstrate to the judges. Our team was awarded the €500 HackTrinity Public Interest Prize for the social impact of our application.
          </p>
        </section>
        <div className="flex flex-col md:flex-row gap-8">
          <div className="w-full md:w-1/2 border rounded-lg p-6 shadow-sm">
            {activeTab === 'YouTube video' && (
              <div>
                <h3 className="text-xl font-bold mb-2" style={{ color: '#007dff' }}>Demonstration Video</h3>
                <div className="aspect-w-16 aspect-h-9">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/LIcfpDsqCG8"
                    title="Demonstration Video Player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            )}
          </div>

          <div className="w-full md:1/2">
            <div className="border rounded-lg p-6 shadow-sm">
              <h2 className="text-2xl font-bold mb-4" style={{ color: '#007dff' }}>Try BorderBuddy AI for Yourself!</h2>
              <p className="mb-4">Register your interest by joining the waitlist below.</p>
              <form className="space-y-4" onSubmit={handleSubmit}>
                <div>
                  <input
                    type="email"
                    id="email"
                    className="w-full border rounded p-2"
                    placeholder="name@example.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <button
                  type="submit"
                  className={`px-4 py-2 rounded w-full flex justify-center items-center ${
                    submitted ? 'bg-gray-400 text-gray-700 cursor-not-allowed' : 'bg-blue-500 text-white'
                  }`}
                  disabled={submitted || loading} // Disable button when loading
                >
                  {loading ? 'Loading...' : submitted ? "We'll notify you when we're ready for you to try BorderBuddy AI!" : 'Notify Me'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </main>

      <footer className="mt-8 border-t pt-4">
        <h3 className="text-lg font-semibold mb-2">Connect with Our Team</h3>
        <div className="flex flex-wrap gap-4">
          {linkedInProfiles.map((profile, index) => (
            <a
              key={index}
              href={profile.url}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center text-blue-500 hover:text-blue-700"
            >
              <Linkedin className="mr-1" size={20} />
              {profile.name}
            </a>
          ))}
        </div>
      </footer>
    </div>
  );
}
